import {
  appleIdState,
  BackButton,
  Button,
  Checkbox,
  gql,
  kakaoIdState,
  Label,
  PhoneNumberField,
  Section,
  TextField,
  ToggleButton,
  TopNavbar,
  useMutation,
  useQueryParams,
  useRecoilValue,
  useShowAlert,
  useSignup,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Bridge } from '../bridge';
import { appleName } from '../plugins/ridge';
import { Gender, Role } from '../types';

const CreatePhone = gql`
  mutation CreatePhone($number: String!) {
    createPhone(number: $number) {
      id
    }
  }
`;

const VerifyPhone = gql`
  mutation VerifyPhone($id: Int!, $code: String!) {
    verifyPhone(id: $id, code: $code) {
      id
    }
  }
`;

export const SignupFormPage = () => {
  const showAlert = useShowAlert();
  const kakaoId = useRecoilValue(kakaoIdState);
  const appleId = useRecoilValue(appleIdState);
  const { isPro } = useQueryParams();
  const role = isPro ? Role.PRO : Role.USER;
  const [gender, setGender] = useState(Gender.MALE);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneId, setPhoneId] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [assocName, setAssocName] = useState('');
  const [assocMemberNumber, setAssocMemberNumber] = useState('');
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [marketingConsented, setMarketingConsented] = useState(false);
  const fullName = appleName.useValue();
  const signupData: any = {
    role,
    name,
    gender,
    assocName,
    assocMemberNumber,
    marketingConsented,
    phoneId,
    phoneNumber,
    phoneCode,
  };

  const signup = useSignup(
    kakaoId
      ? { kakaoId, ...signupData }
      : appleId
      ? { appleId, ...signupData }
      : { email, password, ...signupData },
    (error) => {
      console.log(error);
      showAlert('error', error.message);
    },
  );

  const [createPhone] = useMutation(CreatePhone, {
    variables: { number: phoneNumber },
    onCompleted: ({ createPhone: { id } }) => {
      setPhoneId(id);
      alert('인증번호가 발송되었습니다.');
    },
  });

  const [verifyPhone] = useMutation(VerifyPhone, {
    variables: { id: phoneId, code: phoneCode },
    onCompleted: () => setPhoneVerified(true),
    onError: (error) => alert(error),
  });

  return (
    <>
      <TopNavbar
        title={isPro ? '프로 회원가입' : '회원 상세정보 입력'}
        left={<BackButton />}
      />

      <Section>
        {/* <div>
          <Label text="성별" />
          <div className="flex space-x-3">
            <ToggleButton
              text="남자"
              active={gender === Gender.MALE}
              onClick={() => setGender(Gender.MALE)}
            />
            <ToggleButton
              text="여자"
              active={gender === Gender.FEMALE}
              onClick={() => setGender(Gender.FEMALE)}
            />
          </div>
        </div> */}

        <TextField
          label="이름"
          placeholder="이름을 입력해주세요."
          value={appleId ? fullName : name}
          onChange={(e) => setName(e.target.value)}
        />
        {!isPro && appleId && (
          <div className="text-12">* 예약 확인을 위해 실명을 입력해주세요.</div>
        )}
        <PhoneNumberField
          label="핸드폰 번호"
          disabled={phoneVerified}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {isPro && appleId && (
          <div className="text-12">* 예약확인을 위해 사용됩니다.</div>
        )}
        {!phoneId && (
          <Button
            variant="outlined"
            text="인증번호 보내기"
            disabled={phoneNumber.length < 10}
            onClick={() => createPhone()}
          />
        )}
        {phoneId && !phoneVerified && (
          <div className="flex space-x-3">
            <div className="flex-1">
              <TextField
                placeholder="인증번호를 입력해주세요."
                autoFocus
                maxLength={4}
                value={phoneCode}
                onChange={(e) => setPhoneCode(e.target.value)}
              />
            </div>
            <Button
              text="재전송"
              variant="outlined"
              tw={{ height: 'h-12' }}
              onClick={() => createPhone()}
            />
            <Button
              text="확인"
              tw={{ height: 'h-12' }}
              onClick={() => verifyPhone()}
            />
          </div>
        )}
        {phoneVerified && (
          <div className="text-right text-xs text-gray-500">
            인증이 완료되었습니다.
          </div>
        )}
        {!kakaoId && !appleId && (
          <>
            <TextField
              type="email"
              label="이메일 주소"
              placeholder="이메일 주소를 입력해주세요."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="password"
              label="비밀번호"
              placeholder="비밀번호를 입력해주세요."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="비밀번호 확인"
              placeholder="비밀번호를 다시 한 번 입력해주세요."
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </>
        )}
        {isPro && (
          <>
            <TextField
              label="협회명"
              placeholder="협회명을 입력해주세요."
              value={assocName}
              onChange={(e) => setAssocName(e.target.value)}
            />
            {appleId && (
              <div className="text-12">
                * 프로 진위여부를 확인하기 위함입니다.
              </div>
            )}
            <TextField
              label="협회 회원번호"
              placeholder="협회 회원번호를 입력해주세요."
              value={assocMemberNumber}
              onChange={(e) => setAssocMemberNumber(e.target.value)}
            />
            {appleId && (
              <div className="text-12">
                * 프로 진위여부를 확인하기 위함입니다.
              </div>
            )}
          </>
        )}
      </Section>

      <Section tw={{ spaceY: 'space-y-2' }}>
        <div className="font-bold">
          <Checkbox
            label="전체 동의"
            onChange={() => {
              const allChecked = terms && privacy && marketingConsented;
              setTerms(!allChecked);
              setPrivacy(!allChecked);
              setMarketingConsented(!allChecked);
            }}
            checked={terms && privacy && marketingConsented}
          ></Checkbox>
        </div>
        <div className="flex space-x-2">
          <Checkbox onChange={() => setTerms(!terms)} checked={terms} />
          <a
            href="https://www.notion.so/The-six-studio-d443eb9b333b430fb540a0f97075481d"
            target="blank"
            className="font-semibold"
          >
            이용약관
          </a>
          에 동의 (필수)
        </div>

        <div className="flex space-x-2">
          <Checkbox onChange={() => setPrivacy(!privacy)} checked={privacy} />
          <a
            href="https://www.notion.so/The-six-studio-2dc6d57653cb47dc91ddfff9592e4c56"
            target="blank"
            className="font-semibold"
          >
            개인정보 수집·이용
          </a>
          에 동의 (필수)
        </div>
        <div className="flex space-x-2">
          <Checkbox
            onChange={() => setMarketingConsented(!marketingConsented)}
            checked={marketingConsented}
          />
          <a
            href="https://www.notion.so/The-six-studio-d64bb6e6f323448da3dfd32fe236354c"
            target="blank"
            className="font-semibold"
          >
            이벤트 및 마케팅 정보 수신
          </a>
          에 동의 (선택)
        </div>
      </Section>

      <Section>
        <Button
          text="완료"
          disabled={
            !(
              (kakaoId || appleId || (email && password && password2)) &&
              name &&
              terms &&
              privacy &&
              phoneId &&
              phoneVerified
            )
          }
          onClick={() => signup()}
        />
      </Section>
    </>
  );
};
