import {
  BackButton,
  BottomFixedButton,
  FlexWrap,
  gql,
  LoadingScreen,
  Section,
  TopNavbar,
  useMutation,
  useQuery,
  useQueryParams,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckButton } from '../components';
import {
  Branch,
  ReservationTargetType,
  ReservationType,
  Room,
  Timeslot,
} from '../types';
import { ErrorPage } from './ErrorPage';

const RoomReservationAddPageQuery = gql`
  query RoomReservationAddPage(
    $branchId: Int!
    $roomId: Int!
    $timeslotId: Int!
  ) {
    branch(id: $branchId) {
      id
      name
      remarks
    }
    room(id: $roomId) {
      id
      name
    }
    timeslot(id: $timeslotId) {
      id
      startedAt
      endedAt
    }
  }
`;

const MakeReservation = gql`
  mutation MakeReservation(
    $targetType: ReservationTargetType!
    $roomId: Int!
    $reservationType: ReservationType!
    $startOfDate: DateTime!
    $timeslotId: Int!
  ) {
    makeReservation(
      targetType: $targetType
      targetId: $roomId
      type: $reservationType
      startOfDate: $startOfDate
      timeslotId: $timeslotId
    ) {
      id
    }
  }
`;

export const RoomReservationAddPage = () => {
  const history = useHistory();
  const { branchId, date, roomId, timeslotId } = useQueryParams();

  const { loading, error, data } = useQuery<{
    branch: Branch;
    room: Room;
    timeslot: Timeslot;
  }>(RoomReservationAddPageQuery, {
    variables: { branchId, roomId, timeslotId },
  });

  const [makeRoomReservation] = useMutation(MakeReservation, {
    variables: {
      targetType: ReservationTargetType.ROOM,
      roomId,
      reservationType: ReservationType.ROOM,
      startOfDate: moment(date as string).toISOString(),
      timeslotId,
    },
    onCompleted: () => history.push('/reservation-saved?result=made'),
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <ErrorPage />;
  return (
    <>
      <TopNavbar title="룸 예약 확인" left={<BackButton />} />

      <Section>
        <h3 className="font-bold">지점</h3>
        <div className="text-sm text-gray-600">{data.branch.name}</div>
      </Section>

      <Section>
        <h3 className="font-bold">룸</h3>
        <FlexWrap>
          <CheckButton active>{data.room.name}</CheckButton>
        </FlexWrap>
      </Section>

      <Section>
        <h3 className="font-bold">예약 날짜 및 시간 확인</h3>
        <div className="text-sm text-gray-600">
          {moment(date as string).format('LL')}
        </div>
        <FlexWrap>
          <CheckButton active>
            {utcToLocalFormat(data.timeslot.startedAt, 'a')}
            <br />
            {utcToLocalFormat(data.timeslot.startedAt, 'h:mm')}
          </CheckButton>
        </FlexWrap>
      </Section>

      <Section>
        <h3 className="font-bold">예약 주의사항</h3>
        <div className="p-4 rounded border whitespace-pre-line text-sm bg-gray-100 text-gray-600">
          {data.branch.remarks}
        </div>
      </Section>

      <BottomFixedButton
        tw={{ height: 'h-16' }}
        text="예약하기"
        onClick={() => makeRoomReservation()}
      />
    </>
  );
};
